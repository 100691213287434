import React from "react";
import { Link } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image"


import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import SvgLogo from '../images/svgs/himlatakLogo.inline.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Lyft ditt tak" />
    <div className="firstIntro">
    <div className="intro">
      <SvgLogo style={{height: "120px", margin: "0 auto"}}></SvgLogo>
      </div>
    </div>
    <div className="vector"><svg class="svgRender" id="Lager_1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.5 196.49"><defs style={{ fill: "#fff" }}></defs><title>vector4</title><g id="Lager_2" data-name="Lager 2"><g id="Lager_1-2" data-name="Lager 1"><path class="cls-1" d="M.75.25C1.27.32,2.89.39,3.4.46c4.38.59,918.41,128.85,1177.47,174.28,40.88,7.17,131,23.27,249.8,13,214.43-18.45,382.38-111,490.08-186v195H.25Z" transform="translate(-0.25 -0.25)" /></g></g></svg></div>
    <div style={{ maxWidth: `70rem`, margin: "0 auto", paddingTop: "100vh"}}>
      
          <div className="gradientBg"></div>
      <div className="dualWrapper introWrapper">
        <div className="dualWrapperObj">
          <h1 style={{ fontFamily: "inherit" }}>Lyft ditt tak!</h1>
          <p>
            Himlatak erbjuder virtuella takfönster och fönster som kan förvandla
            vilket rum som helst till en lokal på högsta våningen full av
            energi! Vi erbjuder skräddarsydda lösningar till både privatpersoner
            och företag.
          </p>
        </div>
        <div className="dualWrapperObj">
          <Image
            src="office1.jpg"
            className="mx-auto shadow-xl introImage"
            alt="office with Himlatak"
            style={{
              margin: "0 auto",
            }}
          />
        </div>
      </div>

      <h2
        style={{
          fontFamily: "inherit",
          textAlign: "center",
          marginTop: "50px",
        }}
      >
        Interagera med ditt system
      </h2>
      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <p>
            Antingen styr man himlatak med den vanliga strömställaren för
            belysning med Av/På.Alternativt går det att få alla våra produkter
            med DALI styrenhet vilket gör att du som kund får full kontroll över
            belysning samt integrera Himlatak med befintliga lösningar.
            <br></br> <br></br>
            Vi har även ett samarbete med PLEJD vilket gör det möjligt att styra
            ljusenheterna direkt via telefonen samt skapa automationer.
          </p>
        </div>
        <div className="dualWrapperObj">
          <Image
            src="plejd.jpg"
            className="mx-auto shadow-xl introImage"
            alt="Plejd integration"
            style={{
              margin: "0 auto",
            }}
          />
        </div>
      </div>

      <div className="quote">
        <p>
          <span id="largeQuote">“</span>Wow vilken skillnad det blev! Ett helt
          annat rum!<span id="largeQuote">”</span>
        </p>
      </div>

      <h2
        style={{
          fontFamily: "inherit",
          textAlign: "center",
          marginTop: "50px",
        }}
      >
        Tidigare uppdrag
      </h2>
      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <h2 className="locationTitle">- SATS -</h2>
          <p>
            SATS Sheelegatan i Stockholm ligger under jord men med ett Himlatak
            installerat mitt i lokalen levereras en känsla av att energi i
            lokalen som uppskattas av både medlemmar och personal. Här sitter
            det 6 stycken 60*120 fönster installerade i det befintliga
            takprofilerna.
          </p>
        </div>
        <div className="dualWrapperObj">
          <Image
            src="gym3.jpg"
            className="mx-auto shadow-xl introImage"
            alt="office with Himlatak"
            style={{
              margin: "0 auto",
            }}
          />
        </div>
      </div>
      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <iframe
            src="https://player.vimeo.com/video/453621067?autoplay=1&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
        <div className="dualWrapperObj">
          <p className="textBlockRight">Se Video Himlatak SATS!</p>
        </div>
      </div>
      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <Image
            src="hogbergaGard.jpg"
            className="mx-auto shadow-xl introImage"
            alt="office with Himlatak"
            style={{
              margin: "0 auto",
            }}
          />
        </div>
        <div className="dualWrapperObj">
          <h2 className="textBlockRight">- Högberga Gård -</h2>
          <p className="textBlockRight">
            Med egna vingårdar i Italien levererar Högberga Gård fantastiska
            viner.I deras vinprovningsrum fanns inga fönster och vad kunde inte
            vara mer passande än att ha utsikt över de egna vinrankorna i
            Toscana? Med Himlatak kan man med egna motiv få exakt den utsikten
            man önskar! Se
            Video Himlatak Högberga Gård!
          </p>
          <iframe
            src="https://player.vimeo.com/video/453949091?autoplay=1&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <h2 className="locationTitle">- Tandläkarhuset Bromma -</h2>
          <p>
            Med målet att ge patienterna en positiv känsla lät man montera ett
            Himlatak med skogstema ovan tandläkarstolen. Grönt skogstema ger
            enligt forskningen en känsla av lugn och harmoni.90% av patienterna
            nämner att de uppskattar utsikten där personalen likaså uppskattar
            arbetsmiljön. Se video Himlatak Tandläkarhuset Bromma!
          </p>
        </div>
        <div className="dualWrapperObj">
          <Image
            src="tandlakarhuset.jpg"
            className="mx-auto shadow-xl introImage"
            alt="Tandläkarhuset i Bromma"
            style={{
              margin: "0 auto",
            }}
          />
        </div>
      </div>
      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <iframe
            src="https://player.vimeo.com/video/448811660?autoplay=1&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
        <div className="dualWrapperObj">
          <p className="textBlockRight">
            Se Video Himlatak Tandläkarhuset Bromma!
          </p>
        </div>
      </div>

      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <Image
            src="office1.jpg"
            className="mx-auto shadow-xl introImage"
            alt="office with Himlatak"
            style={{
              margin: "0 auto",
            }}
          />
        </div>
        <div className="dualWrapperObj">
          <h2 className="textBlockRight">- Global Blue -</h2>
          <p className="textBlockRight">
            Med konferensrummet på nedre botten utan fönster sökte man en
            lösning att ge energi till mötena.Det blåa ljuset är vetenskapligt
            bevisat att ge just energi varför valet blev en himmel. Se video Himlatak Global
            Blue!
          </p>
          <iframe
            src="https://player.vimeo.com/video/454340135?autoplay=1&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <h2 className="locationTitle">- Solvik Member Club -</h2>
          <p>
            I detta exklusiva träningscenter erbjuder man kunderna att tanka
            ljusenergi under de mörka månaderna. I Whiteroom har man valt en
            utsikt av skog blandat med ljusterapi.Här valde man att bygga en
            vägg av takfönster som vi platsbyggde. I en vanlig kontorslokal är
            ljusstyrkan ca: 500 lumen, i Whiteroom mäter ljusstyrkan dagsljus på
            6000 lumen vilket ger oss människor energi. En kvart om dagen gör en
            stor skillnad! Se video Himlatak Solvik Member Club!
          </p>
        </div>
        <div className="dualWrapperObj">
          <Image
            src="solvik.jpg"
            className="mx-auto shadow-xl introImage"
            alt="Tandläkarhuset i Bromma"
            style={{
              margin: "0 auto",
            }}
          />
        </div>
      </div>
      <div className="dualWrapper">
        <div className="dualWrapperObj">
          <iframe
            src="https://player.vimeo.com/video/454369471?autoplay=1&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
        <div className="dualWrapperObj">
          <p className="textBlockRight">
            Se Video Himlatak Solvik Member Club!
          </p>
        </div>
      </div>

      <div className="getStarted">
        <h2
          style={{
            fontFamily: "inherit",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          Kom igång med Himlatak
        </h2>
        <p>
          Vi erbjuder helhetslösningar skräddarsydda för ditt företag. Vi har
          sammarbete med duktiga ljusdesigners som kan planera hur kontor eller
          anan lokal bäst kan förbättras. Vi kan även erbjuda installation med
          våra installatörer och elektriker men om ni istället vill använda egen
          personal går det också utmärkt.
        </p>
        <Link to="/kontakt/">
          <div className="button">Kontakta oss</div>
        </Link>
      </div>
      <div className="imageBlock">
        <div className="imageBlockItem">
          <Image
            src="hogbergaGard.jpg"
            className="imageBlockItemI"
            alt="Högberga Gård Himlatak"
            style={{}}
          />
          
        </div>
        <div className="imageBlockItem">
          <Image
            src="office1.jpg"
            className="imageBlockItemI"
            alt="office with Himlatak"
            style={{}}
          />
        </div>
        <div className="imageBlockItem">
          <Image
            src="gym3.jpg"
            className="imageBlockItemI"
            alt="Sats Schelegatan"
            style={{}}
          />
        </div>
        {/* Second row */}
        {/* <div className="imageBlockItem">
          <Image
            src="hogbergaGard.jpg"
            className="imageBlockItemI"
            alt="Högberga Gård Himlatak"
            style={{}}
          />
        </div>
        <div className="imageBlockItem">
          <Image
            src="office1.jpg"
            className="imageBlockItemI"
            alt="office with Himlatak"
            style={{}}
          />
        </div>
        <div className="imageBlockItem">
          <Image
            src="gym3.jpg"
            className="imageBlockItemI"
            alt="Sats Schelegatan"
            style={{}}
          />
        </div> */}
      </div>
    </div>
  </Layout>
);

export default IndexPage;
